<template>
  <div class="app">
    <scroll-view class="list" pullTips="false">
      <div class="list-space"></div>

      <div class="list-item">
        <!-- language-ch 支付密码 -->
        <text class="list-item-title">New payment password</text>
        <!-- language-ch 请输入4位数字为支付密码 -->
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="input_pass"
          type="password"
          maxlength="4"
          placeholder="Please enter 4 digits for payment password"
        />
      </div>
      <div class="list-line"></div>
      <div class="list-item">
        <!-- language-ch 短信验证码 -->
        <text class="list-item-title">Message code</text>
        <!-- language-ch 输入手机验证码 -->
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="input_code"
          type="number"
          placeholder="Verification code"
        />
        <div @click="itemClick('getcode')">
          <text class="item-title-getcode">{{ codeTitle }}</text>
        </div>
      </div>

      <div>
        <!-- language-ch 接收短信手机号： -->
        <text class="list-phone"
          >Receive SMS Phone No. :{{ formatMobile(userInfo.userphone) }}</text
        >
      </div>
      <!-- language-ch 提 交 -->
      <button @click="itemClick('submit')" class="button">Submit</button>
    </scroll-view>
  </div>
</template>

<script>
import { SEND_SMS_CODE, SETTING_PAY_PDW } from '@/api'
import { count, getUserInfo, setTimeoutName } from '@/utils'
export default {
  data() {
    return {
      userInfo: {},
      // language-ch 获取验证码
      codeTitle: 'Get code',

      input_pass: '',
      input_code: '',
    }
  },

  mounted() {
    this.userInfo = getUserInfo()
  },

  methods: {
    itemClick(type) {
      switch (type) {
        case 'getcode':
          // #ifdef APP-PLUS
          uni.hideKeyboard()
          // plus.key.hideSoftKeybord();
          // #endif
          // language-ch 获取验证码
          if (this.codeTitle !== 'Get code') {
            return
          }
          // language-ch 正在获取...
          this.codeTitle = 'Is access to...'
          //

          SEND_SMS_CODE({
            userphone: this.userInfo.userphone,
          })
            .then((data) => {
              this.$loading.hide()
              let [error, res] = data
              if (res.data.ret === 1) {
                let countDown = 60
                // language-ch 重新获取 + countDown + '秒'
                this.codeTitle = 'Recapture' + countDown + 's'
                setTimeoutName(
                  'user_login::getcode',
                  () => {
                    countDown--
                    if (countDown <= 0) {
                      this.codeTitle = 'OTP'
                    } else {
                      // language-ch 重新获取 + countDown + '秒'
                      this.codeTitle = 'Recapture' + countDown + 's'
                      return true
                    }
                  },
                  1000
                )
              } else {
                this.$toast(res.msg)
                this.codeTitle = 'OTP'
              }
            })
            .catch((e) => {
              this.$loading.hide()
              console.error(e)
            })
          break

        case 'submit':
          // #ifdef APP-PLUS
          uni.hideKeyboard()
          // plus.key.hideSoftKeybord();
          // #endif
          if (count(this.input_pass) !== 4) {
            // language-ch 请输入4位数支付密码
            this.$toast('Please enter your 4 digit payment password')
            return
          }

          SETTING_PAY_PDW({
            newpass: this.input_pass,
            code: this.input_code,
          })
            .then((data) => {
              this.$loading.hide()
              let [error, res] = data
              this.$toast(res.data.msg)
              if (res.data.ret === 1) {
                uni.navigateBack({
                  delta: 1,
                })
              }
            })
            .catch((e) => {
              this.$loading.hide()
              console.error(e)
            })
          break
      }
    },
    formatMobile(phone) {
      if (count(phone) === 0) {
        return ''
      }
      return phone.substring(0, 3) + '****' + phone.substring(phone.length - 4)
    },
  },
}
</script>

<style scoped>
.app {
  flex: 1;
  width: 750rpx;
  background-color: #f4f8f9;
}
.list {
  flex: 1;
  width: 750rpx;
}
.list-space {
  width: 750rpx;
  height: 20rpx;
  background-color: #f4f8f9;
}
.list-item {
  width: 750rpx;
  height: 100rpx;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
}
.list-item-title {
  font-size: 28rpx;
  margin-left: 24rpx;
  margin-right: 34rpx;
  color: #242424;
}
.list-item-input {
  flex: 1;
  font-size: 28rpx;
  height: 80prx;
  padding-right: 24rpx;
}
.item-title-getcode {
  height: 100rpx;
  line-height: 100rpx;
  font-size: 26rpx;
  margin-left: 24rpx;
  margin-right: 24rpx;
  color: #dc1323;
}
.list-line {
  width: 750rpx;
  height: 1rpx;
  background-color: #ececec;
}
.list-phone {
  text-align: right;
  margin-top: 24rpx;
  margin-right: 24rpx;
  font-size: 24rpx;
  color: #666666;
  margin-left: 24rpx;
}
.button {
  width: 680rpx;
  height: 86rpx;
  font-size: 28rpx;
  margin-top: 62rpx;
  margin-left: 35rpx;
  background-color: #dc1323;
  border-radius: 10rpx;
  line-height: 86rpx;
  color: var(--textColor);
}
</style>
